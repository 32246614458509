import React from 'react';
import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      onceFired: false,
    };
  }

  handleChange({ boundingClientRect, isIntersecting }) {
    const position = boundingClientRect.top > 0 ? 'below' : 'above';

    const { onceVisible, onVisible } = this.props;
    if (onceVisible && isIntersecting) {
      this.setState({ onceFired: true });
      onceVisible({ isVisible: true });
    } else if (onVisible) {
      onVisible({ isVisible: isIntersecting, position });
    }
  }

  render() {
    const { rootMargin } = this.props;

    return (
      <Observer
        rootMargin={rootMargin}
        {...this.props}
        onChange={obj => !this.state.onceFired && this.handleChange(obj)}
      />
    );
  }
}

Component.defaultProps = {
  rootMargin: '900px 900px 900px 900px',
};

Component.propTypes = {
  children: PropTypes.element,
  onceVisible: PropTypes.func,
  onVisible: PropTypes.func,
  rootMargin: PropTypes.string,
};

export { Component as Observer };
